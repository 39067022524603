import { requestPermissions, getActiveAccount, clearActiveAccount, wallet } from '../../utils/tezos';
import tzdomains from './../../utils/tezos-domains';

export default {

  async connectWallet({ commit, state }) {
    if (!state.connected) {
      return getActiveAccount().then((account) => {
        if (account) {
          commit('updateWallet', {
            connected: true,
            pkh: account.address,
            pkhDomain: tzdomains.resolveAddressToName(account.address, `${account.address.substr(0, 6)}...${account.address.substr(-6)}`),
          });
        }
      });
    }
  },

  async disconnectWallet({ commit }) {
    clearActiveAccount().then(() => {
      commit('updateWallet', {
        connected: false,
        pkh: '',
        pkhDomain: Promise.resolve(''),
      });
    });
  },

  async checkWalletConnected({ commit }) {
    wallet.client.getActiveAccount().then((account) => {
      if (account) {
        commit('updateWallet', {
          connected: true,
          pkh: account.address,
          pkhDomain: tzdomains.resolveAddressToName(account.address, `${account.address.substr(0, 6)}...${account.address.substr(-6)}`),
        });
      }
    });
  },

  async changeWallet({ dispatch }) {
    requestPermissions().then(() => {
      dispatch('checkWalletConnected');
    });
  }

}
