<template>
<div id="app" v-loading="swapPending || fusePending" element-loading-background="rgba(0, 0, 0, 0.65)">
  <el-container class="mw-layout">

    <el-header style="height: auto;">
      <img src="./assets/ottez-logo-negative.svg" class="logo" />
      <div class="links">
        <a :href="discordUrl"><i class="fab fa-discord"></i></a>
        <a :href="twitterUrl"><i class="fab fa-twitter"></i></a>
        <el-button plain v-if="checkWalletConnected() && wallet.connected === false" @click="connectWallet">Sync</el-button>
        <el-button plain v-else @click="disconnectWallet">Unsync</el-button>
        <span v-if="wallet.connected" class="hidden-xs-only" style="margin-left: 15px;">{{ `${wallet.pkh.substr(0, 6)}...${wallet.pkh.substr(-6)}` }}</span>
      </div>
    </el-header>

    <el-main class="main">

      <el-row :gutter="40" style="margin-top: 25px;" class="fuse">
        <el-col :sm="24" :md="8" style="border-right: 2px solid #2a2b2d">
          <div style="font-weight: bold; font-size: 1.1em;">Fusion Candidate <span style="color: #dc7551;">1</span></div>
          <div v-if="fuseToken1" key="fuseToken1.id">
            <div class="fusion-image" style="margin: 16px 0;">
              <img :src="`https://ipfs.cclabs.tech/ipfs/QmbTj2gembQr1DFa961k3J6gsvA72YtXyhCqKVR9iK5UZk/ottez-${fuseToken1.id}-display.png`" style="max-width: 100%; border-radius: 8px" />
            </div>
          </div>
          <div v-else>
            <div class="fusion-image" style="margin: 16px 0;">
              <img src="./assets/ottez-fusion-holding-img.png" style="max-width: 100%; border-radius: 8px" />
            </div>
          </div>
          <el-input
            placeholder="Type token ID here"
            v-model="fuseId1"
            @input="handleFuseId1Change"
            clearable>
          </el-input>
          <div v-if="fuseToken1" :key="`${fuseToken1.id}-attributes`" style="margin: 16px 0;">
            <div style="font-weight: bold; font-size: 1.1em; margin: 16px 0;">Select attributes to fuse:</div>
            <el-row :gutter="24">
              <el-col :sm="24" :md="12" v-for="attribute in fuseToken1.attributes" :key="`${fuseToken1.id}-${attribute.name}`">
                <el-radio v-model="fuseTokenAttributes[attribute.name]" :label="attribute.dna" border>{{ attribute.name }}<span class="label-value">{{ attribute.value }}</span></el-radio>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :sm="24" :md="8" style="border-right: 2px solid #2a2b2d">
          <div style="font-weight: bold; font-size: 1.1em;">Fusion Candidate <span style="color: #dc7551;">2</span></div>
          <div v-if="fuseToken2" key="fuseToken2.id">
            <div class="fusion-image" style="margin: 16px 0;">
              <img :src="`https://ipfs.cclabs.tech/ipfs/QmbTj2gembQr1DFa961k3J6gsvA72YtXyhCqKVR9iK5UZk/ottez-${fuseToken2.id}-display.png`" style="max-width: 100%; border-radius: 8px" />
            </div>
          </div>
          <div v-else>
            <div class="fusion-image" style="margin: 16px 0;">
              <img src="./assets/ottez-fusion-holding-img.png" style="max-width: 100%; border-radius: 8px" />
            </div>
          </div>
          <el-input
            placeholder="Type token ID here"
            v-model="fuseId2"
            @input="handleFuseId2Change"
            clearable>
          </el-input>
          <div v-if="fuseToken2" :key="`${fuseToken2.id}-attributes`" style="margin: 16px 0;">
            <div style="font-weight: bold; font-size: 1.1em; margin: 16px 0;">Select attributes to fuse:</div>
            <el-row :gutter="24">
              <el-col :sm="24" :md="12" v-for="attribute in fuseToken2.attributes" :key="`${fuseToken2.id}-${attribute.name}`">
                <el-radio v-model="fuseTokenAttributes[attribute.name]" :label="attribute.dna" border>{{ attribute.name }}<span class="label-value">{{ attribute.value }}</span></el-radio>
              </el-col>
            </el-row>
          </div>
        </el-col>
        <el-col :sm="24" :md="8">
          <div style="font-weight: bold; font-size: 1.1em;">Fused <span style="color: #dc7551;">Gen2</span></div>
          <div class="fusion-image" style="margin: 16px 0;" v-loading="fusePreviewLoading" element-loading-background="rgba(0, 0, 0, 0.65)">
            <img v-if="fusePreviewImg" :src="fusePreviewImg" style="max-width: 100%; border-radius: 8px" />
            <img v-else src="./assets/ottez-fusion-holding-img.png" style="max-width: 100%; border-radius: 8px" />
          </div>
          <el-button type="warning" v-if="fuseToken1 && fuseToken2" @click="previewFusion" :disabled="fusionDisabled" style="width: 100%; color: #000;">FUSION PREVIEW</el-button>
          <el-button type="success" v-if="fuseToken1 && fuseToken2 && fusePreviewImg" @click="doFusion" :disabled="fusionDisabled" style="width: 100%; margin-left: 0; margin-top: 12px; color: #000; padding: 20px 0;">FUSE</el-button>
          <div v-if="fuseComplete" style="margin-top: 16px;">Congratulations! The Fusion process was successful. Your Gen2 Ottez will arrive in your wallet shortly.</div>
          <div><el-button type="text" style="margin-top: 16px; font-weight: bold;" @click="resetFusion">Reset the Fusion Chamber<i class="far fa-rotate-left" style="margin-left: 8px;"></i></el-button></div>
        </el-col>
      </el-row>


      <el-row :gutter="24" style="margin-top: 125px; padding-top: 75px; border-top: 2px solid #2a2b2d" class="swap">
        <el-col :sm="24" :md="12">
          <h1 style="margin-top: 0;">Fusion is here.<br/><span>Get Yours.</span></h1>
          <p class="lg">Swap Oyster Pearls for a Fusion token. 500 Pearls can be swapped for 500 Fusion tokens.</p>
          <p>Fusion requires owning at least 2 Ottez + 1 Fusion token.</p>
          <el-button @click="gotoObjkt" style="width: 100%; margin: 15px 0;">View Ottez on Objkt<i class="far fa-external-link" style="margin-left: 8px;"></i></el-button>
          <el-button @click="gotoObjktOysters" style="width: 100%; margin: 15px 0;">View Oysters on Objkt<i class="far fa-external-link" style="margin-left: 8px;"></i></el-button>
          <el-button @click="gotoObjktFusion" style="width: 100%; margin: 15px 0;">View Fusion Tokens on Objkt<i class="far fa-external-link" style="margin-left: 8px;"></i></el-button>

          <el-button plain v-if="checkWalletConnected() && wallet.connected === false" @click="connectWallet" style="width: 100%; margin: 15px 0;">Sync Wallet</el-button>
          <el-button type="primary" v-if="wallet.connected" @click="swap" style="width: 100%; margin: 15px 0;">Swap Pearls for Fusion Tokens</el-button>

        </el-col>
        <el-col :sm="24" :md="12" style="text-align: center;">
          <img src="./assets/fusion.png" style="max-width: 100%" />
        </el-col>
      </el-row>

    </el-main>
    <!-- <el-footer style="height: auto;">
      <img src="./assets/ottez-logo-negative.svg" class="logo" />
      <div class="links">
        <a :href="discordUrl" target="_blank"><i class="fab fa-discord"></i></a>
        <a :href="twitterUrl" target="_blank"><i class="fab fa-twitter"></i></a>
        <a href="https://www.instagram.com/otteznft/" target="_blank"><i class="fab fa-instagram"></i></a>
      </div>
    </el-footer> -->
  </el-container>

</div>
</template>

<script>
// import Vue from 'vue';
import { mapState, mapActions } from 'vuex'
import { getBatch, getWalletContract } from './utils/tezos';
import axios from 'axios'
import ipfs from './utils/ipfs';
import tokenTraits from './assets/traits.json';

export default {
  name: 'app',
  data() {
    return {
      discordUrl: "https://discord.gg/PN9mCTb8",
      twitterUrl: "https://twitter.com/otteznft",

      fuseId1: '',
      fuseId2: '',
      fuseToken1: null,
      fuseToken2: null,
      fuseTokenAttributes: {
        "Background": "",
        "Fur": "",
        "Tail": "",
        "Mouth": "",
        "Eyes": "",
        "Clothing": "",
        "Piercings": "",
        "Headgear": "",
        "Eyewear": "",
      },
      fusePreviewLoading: false,
      fusePreviewImg: '',
      fusePending: false,
      fuseComplete: false,

      swapPending: false,
      pearlTokenIds: [],
    }
  },
  computed: {
    ...mapState([
      'wallet'
    ]),

    fusionDisabled: function() {
      return Object.values(this.fuseTokenAttributes).filter(v => v === "").length > 0;
    }

  },
  created() {

  },
  methods: {
    ...mapActions([
      'checkWalletConnected',
      'connectWallet',
      'disconnectWallet',
      'changeWallet'
    ]),

    transformUri(uri) {
      return ipfs.transformUri(uri);
    },

    gotoObjkt() {
      window.open('https://objkt.com/collection/ottez');
    },

    gotoObjktOysters() {
      window.open('https://objkt.com/collection/ottez_oysters');
    },

    gotoObjktFusion() {
      window.open('https://objkt.com/asset/KT1RiFVovoQbYmYuDPyrEcXjoEKmcoyeSQGh/6');
    },

    handleFuseId1Change() {
      if (this.fuseId1 && Object.prototype.hasOwnProperty.call(tokenTraits, this.fuseId1)) {
        this.fuseToken1 = tokenTraits[this.fuseId1];
      } else {
        this.fuseToken1 = null;
      }
    },

    handleFuseId2Change() {
      if (this.fuseId2 && Object.prototype.hasOwnProperty.call(tokenTraits, this.fuseId2)) {
        this.fuseToken2 = tokenTraits[this.fuseId2];
      } else {
        this.fuseToken2 = null;
      }
    },

    async previewFusion() {
      this.fusePreviewLoading = true;
      this.fusePreviewImg = '';
      const dnaString = Object.values(this.fuseTokenAttributes).join('');
      const res = await (await fetch(`https://api.ottezfusion.xyz/preview/${dnaString}`)).json();
      if (res.preview) {
        this.fusePreviewImg = res.preview;
      }
      this.fusePreviewLoading = false;
    },

    resetFusion() {
      this.fuseId1 = '';
      this.fuseId2 = '';
      this.fuseToken1 = null;
      this.fuseToken2 = null;
      this.fuseTokenAttributes = {
        "Background": "",
        "Fur": "",
        "Tail": "",
        "Mouth": "",
        "Eyes": "",
        "Clothing": "",
        "Piercings": "",
        "Headgear": "",
        "Eyewear": "",
      };
      this.fusePreviewLoading = false;
      this.fusePreviewImg = '';
      this.fusePending = false;
      this.fuseComplete = false;
    },

    async doFusion() {
      this.fusePending = true;
      try {
        const batch = getBatch();
        const fuseK = await getWalletContract("KT19oLahfvtuEHWBGzWKYuUqpt2s78isuPvq");
        const fa2K = await getWalletContract("KT1RiFVovoQbYmYuDPyrEcXjoEKmcoyeSQGh");

        batch.withContractCall(
          fa2K.methods.update_operators([
            {
              add_operator: {
                owner: this.wallet.pkh,
                operator: "KT19oLahfvtuEHWBGzWKYuUqpt2s78isuPvq",
                token_id: 6,
              },
            }
          ])
        );

        batch.withTransfer(
          fuseK.methods.fuse(this.fuseId1, this.fuseId2, Object.values(this.fuseTokenAttributes).join(''))
            .toTransferParams({ amount: 20 * 10**6, mutez: true })
        );

        batch.withContractCall(
          fa2K.methods.update_operators([
            {
              remove_operator: {
                owner: this.wallet.pkh,
                operator: "KT1CYai1jmsNK129sWhNWn1mZa9UExtdqDxq",
                token_id: 6,
              },
            }
          ])
        );

        const tx = await batch.send();
        await tx.confirmation();
        this.fuseComplete = true;
      } catch (e) {
        console.log(e);
      }
      this.fusePending = false;
    },

    async updateTokens() {
      if (this.wallet.pkh) {
        const res = await axios.get(`https://api.tzkt.io/v1/tokens/balances?token.contract=KT1QYVvQb1NCMWLLSAT7YcYtmmuEiKtTHmwd&balance.ge=1&account=${this.wallet.pkh}&select=token.tokenId&limit=1000`);
        this.pearlTokenIds = res.data;
      }
    },

    async swap() {
      try {
        this.swapPending = true;

        await this.updateTokens();

        if (this.pearlTokenIds.length < 1) {
          this.swapPending = false;
          this.$notify.error({
            title: 'Error',
            message: 'You have no pearl tokens to swap.',
            duration: 0,
          });
          return;
        }

        const batch = getBatch();
        const fa2K = await getWalletContract("KT1QYVvQb1NCMWLLSAT7YcYtmmuEiKtTHmwd");
        const swapK = await getWalletContract("KT1MyWyPqvrcq9F4JRLGzFTozBhnzhN4mwWS")

        let update = [];
        for (const tokenId of this.pearlTokenIds) {
          update.push({
            add_operator: {
              owner: this.wallet.pkh,
              operator: "KT1MyWyPqvrcq9F4JRLGzFTozBhnzhN4mwWS",
              token_id: tokenId,
            },
          });
        }

        batch.withTransfer(
          fa2K.methods.update_operators(update)
            .toTransferParams({ storageLimit: this.pearlTokenIds.length * 75 })
        );

        batch.withContractCall(swapK.methods.swap(this.pearlTokenIds));

        update = [];
        for (const tokenId of this.pearlTokenIds) {
          update.push({
            remove_operator: {
              owner: this.wallet.pkh,
              operator: "KT1MyWyPqvrcq9F4JRLGzFTozBhnzhN4mwWS",
              token_id: tokenId,
            },
          });
        }

        batch.withTransfer(
          fa2K.methods.update_operators(update)
            .toTransferParams({ storageLimit: this.pearlTokenIds.length * 75 })
        );

        const tx = await batch.send();
        await tx.confirmation();
      } catch (e) {
        console.log(e);
      }

      this.swapPending = false;
    },

  }
}
</script>

<style lang="scss">
html,
body {
  margin: 0;
  height: 100%;
  background: #000;
}

body {
  font-family: 'DM Sans', sans-serif;
  color: #fff;
}

#app {
  height: 100vh;
}

.mw-layout {
  max-width: 1280px;
  margin: 0 auto;
}


.el-main {
  padding-left: 20px !important;
  padding-right: 20px !important;
  &.main {
    margin-top: 50px;
  }
}

#app .el-button.is-plain {
  background: transparent;
  border: 2px solid #dc7551;
  border-color: #dc7551;
  text-transform: uppercase;
  color: #fff;
  font-weight: 500;
  padding: 10px 25px;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 2px;
  border-radius: 5px;
}
#app .el-button.is-plain:hover {
  border-color: #6a5e9e;
  color: #fff;
}

#app .el-select {
  width: 80px;
  margin-right: 15px;
}

#app .el-input__inner {
  background: #2a2b2d;
  color: #fff;
  border-radius: 6px;
  border-width: 2px;
  border-color: #fff;

  &::placeholder {
    color: #888;
  }

  &:focus {
    border-color: #dc7551;
  }
}

.el-header, .el-footer {
  position: relative;
}

.el-footer {
    margin: 100px 0 !important;
}

.logo {
  width: 95px;
  margin-top: 15px;
}

.links {
  position: absolute;
  top: 18px;
  right: 20px;
}

.links a,
.links a:visited {
  display: block;
  margin-top: 6px;
  float: left;
  font-size: 28px;
  color: #fff;
  margin-right: 24px;
  line-height: 32px;
}
.links a:hover {
  color: #6a5e9e;
}


h1 {
  color: #fff;
  font-family: 'Rancho', cursive;
  font-size: 64px;
  margin-bottom: 32px;
  line-height: 66px;

  span {
    color: #d19f49;
  }
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #9F9F9F;
  margin-bottom: 24px;

  &.lg {
    font-size: 24px;
    line-height: 32px;
  }

  a {
    color: #dc7551;
    text-decoration: none;
    &:hover {
      color: #6a5e9e;
      text-decoration: underline;
    }
  }
}

.fuse {

  .el-radio {
    &.is-bordered {
      width: 100%;
      background: #2a2b2d;
      border: none;
      border-radius: 6px;
      height: auto;
      text-align: center;
      padding: 12px 0;
      margin-bottom: 12px;
    }

    .el-radio__input {
      display: none;
    }

    .el-radio__label {
      padding: 0;
      color: #777;

      .label-value {
        display: block;
        font-weight: bold;
        font-size: 1.1em;
        padding-top: 8px;
        color: #fff;
      }
    }

    &.is-checked {
      background: #dc7551;
      .el-radio__label {
        color: #000;
        .label-value {
          color: #000;
        }
      }
    }
  }

}

.swap {
  .el-button {
      padding: 15px 75px;
      font-size: 18px;
      letter-spacing: 2px;
  }
}

@media only screen and (max-width: 991px) {
  body {
    background-size: 200% auto;
  }
  .el-main {
    &.main {
      margin-top: 56%;
    }
  }
  .el-footer {
      margin: 20px 0 !important;
  }
}

</style>
