import actions from './actions'
import mutations from './mutations'

export default {
  state: {
    connected: false,
    network: process.env.VUE_APP_TEZOS_NETWORK,
    pkh: '',
    pkhDomain: Promise.resolve('')
  },
  actions,
  mutations
}
